<template>
  <div class="grid">
    <Toast />
    <div class="col-12">
      <div class="card">
        <h5>Atualizar Senha</h5>
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-6">
            <label for="currentPassword">Senha Atual *</label>
            <Password id="currentPassword" toggleMask v-model="passwordDto.currentPassword" required="true" autofocus :class="{'p-invalid': submitted && !passwordDto.currentPassword}" />
            <small class="p-invalid" v-if="submitted && !passwordDto.currentPassword">Senha Atual é obrigatório.</small>
          </div>
          <div class="field col-12 md:col-6"/>
          <div class="field col-12 md:col-6">
            <label for="newPassword">Nova Senha *</label>
            <Password id="newPassword" v-model="passwordDto.newPassword" toggleMask required="true" :class="{'p-invalid': submitted && !passwordDto.newPassword}"/>
            <small class="p-invalid" v-if="submitted && !passwordDto.newPassword">Nova Senha é obrigatório.</small>
          </div>
          <div class="field col-12 md:col-6">
            <label for="confirmPassword">Confirmar Senha *</label>
            <Password id="confirmPassword" v-model="confirmPassword" toggleMask required="true" :class="{'p-invalid': submitted && !confirmPassword}"/>
            <small class="p-invalid" v-if="submitted && !confirmPassword">Confirmar Senha é obrigatório.</small>
          </div>
        </div>
        <Toolbar class="mb-2">
          <template v-slot:end>
            <div class="my-2">
              <Button label="Cancelar" icon="pi" class="p-button-outlined mr-2" @click="$router.back()"/>
              <Button label="Atualizar" icon="pi pi-save" class="p-button-success" @click="update"/>
            </div>
          </template>
        </Toolbar>
      </div>
    </div>
  </div>
</template>

<script>
import {useVuelidate} from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import UserService from "@/service/UserService";

export default {
  name: "profile-user",
  setup: () => ({
    v$: useVuelidate()
  }),
  validations () {
    return {
      passwordDto: {
        currentPassword: {required},
        newPassword: {required}
      },
      confirmPassword: {required},
    }
  },
  created() {
    this.userService = new UserService();
  },
  data() {
    return {
      userService: null,
      passwordDto: {},
      submitted: false,
      confirmPassword: null
    }
  },
  async mounted() {
  },
  methods: {
    async update() {
      const result = await this.v$.$validate();
      if(result) {
        if(this.passwordDto.newPassword === this.confirmPassword) {
          this.passwordDto.id = JSON.parse(localStorage.getItem("user")).id;
          const response = await this.userService.updatePassword(this.passwordDto);
          if(response.status === 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'Sucesso',
              detail: 'Senha atualizada com sucesso.',
              life: 3000
            });
            this.clearForm();
            localStorage.removeItem("access_token");
            await this.$router.push("/");
          } else if (response.status === 210){
            this.$toast.add({severity:'warn', summary: 'Alerta', detail:'A senha atual não confere com a informada.', life: 3000});
          }
        } else {
          this.$toast.add({severity:'error', summary: 'Ops', detail:'As senhas não conferem.', life: 3000});
        }
      } else {
        this.submitted = true;
      }
    },
    clearForm() {
      this.passwordDto = {};
      this.$router.back();
    }
  }
}
</script>

<style scoped>
</style>
